/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Nothing+You+Could+Do&display=swap');
body{
  font-family: "Inter", sans-serif;;
}
.themeColor{
    color: #7A68F6;
}


.shadow2{
    box-shadow: 0px 1px 10px 0 #7872dd, 0px 1px 0px 3px #ffffff !important;
}


::ng-deep asl-google-signin-button .LgbsSe-Bz112c{
    display: none !important;
}

textarea::selection {
    background: #EBEAFF !important; /* Change this to your desired background color */
    color: #000000 !important; /* Change this to your desired text color */
  }




  ::selection {
    background: #EBEAFF !important; /* Change this to your desired background color */
    color: #000000; /* Change this to your desired text color */
  }

.fuse-vertical-navigation-item:hover .whiteic,.fuse-vertical-navigation-item-active .whiteic{
  display: inline;
} 

.fuse-vertical-navigation-item:hover .grayic,.fuse-vertical-navigation-item-active .grayic{
  display: none;
} 

 .whiteic{
    display: none;
  }


  .spalash-rotate-animation {
    animation: rotate 3s linear infinite;
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }


  @media screen and (max-width: 600px) {

    .hidemobile{
        display: none !important;
    }
  }